<template>
    <div class="client-add" v-if="fetched">
        <div class="vx-row mb-base">
            <div class="vx-col lg:w-1/2 w-full">
                <vx-card title="Informationen" class="h-full">
                    <div class="vx-row">
                        <div class="vx-col lg:w-full w-full">
                            <div class="vx-row">



                                <div class="vx-col lg:w-full w-full">
                                    <vs-col vs-type="flex" class="rx-row mt-4 pb-3">
                                        <vs-col class="vx-col lg:w-1/3">
                                            <vs-col>
                                                <vs-col vs-type="flex" vs-align="center" vs-w="7">
                                                    <vs-switch color="success" v-model="clientData.active" icon-pack="feather"
                                                               vs-icon="icon-check"/>
                                                    <label class="switch-label">Status</label>
                                                </vs-col>
                                                <vs-col vs-type="flex" vs-align="center" vs-w="7" class="pt-2">
                                                    <vs-switch color="success" v-model="clientData.fast_registration"  v-if="clientData.fast_registration == 0 || clientData.fast_registration == false"
                                                               icon-pack="feather" vs-icon="icon-check" @click="selectFastRegistration()"/>

                                                    <vs-switch color="success" v-model="clientData.fast_registration" v-if="clientData.fast_registration == 1 || clientData.fast_registration == true"
                                                               icon-pack="feather" vs-icon="icon-check" />
                                                    <label class="switch-label">Schnellkunde</label>
                                                </vs-col>
                                            </vs-col>
                                        </vs-col>
                                        <div class="vx-col lg:w-1/3">
                                            <vs-col vs-type="flex" vs-align="center" vs-w="7">
                                                <vs-switch color="success" icon-pack="feather"
                                                           v-model="clientData.business_client" vs-icon="icon-check"/>
                                                <label class="switch-label">Geschäftskunde</label>
                                            </vs-col>
                                        </div>
                                        <div class="vx-col lg:w-1/3">
                                            <p>Typ:
                                                <mg-select :options="typeOptions" v-model="clientData.type" @select="onSelectCustomerType"
                                                           @remove="clientData.type=null"
                                                           placeholder="Type auswählen" track-by="value" label="label"
                                                         >
                                                </mg-select>


                                            </p>
                                            <p>Categorie: XX</p>
                                        </div>
                                    </vs-col>
                                    <vs-divider/>
                                </div>




                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                  <vs-input  disabled="disabled" v-model="clientData.customer_number" class="w-full" label="Kundennummer *" type="text"></vs-input>
                                </div>
                            </div>
                          <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Firma *" data-vv-as="Firma" name="company_name" v-model="clientData.company_name" type="text" v-validate="'required'"/>
                                    <span class="text-danger text-sm" v-if="errors.has('company_name')">{{ errors.first('company_name') }}</span>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Anschrift 1 *" data-vv-as="Anschrift 1" name="address_line_1" v-model="clientData.address_line_1" type="text" v-validate="'required'"/>
                                    <span class="text-danger text-sm" v-if="errors.has('address_line_1')">{{ errors.first('address_line_1') }}</span>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Anschrift 2" data-vv-as="Anschrift 2" name="address_line_2" v-model="clientData.address_line_2" type="text" />
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-1/3">
                                    <vs-input class="w-full" label="PLZ *" data-vv-as="PLZ" name="zip_code" v-model="clientData.zip_code" type="text" v-validate="'required'" />
                                    <span class="text-danger text-sm" v-if="errors.has('zip_code')">{{ errors.first('zip_code') }}</span>
                                </div>

                                <div class="vx-col lg:w-2/3">
                                    <vs-input class="w-full" label="Stadt *" data-vv-as="Standort" name="city" v-model="clientData.city" type="text" v-validate="'required'" />
                                    <span class="text-danger text-sm" v-if="errors.has('city')">{{ errors.first('city') }}</span>
                                </div>
                            </div>

                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-1/3">
                                    <vs-select label="Land" class="w-full" v-model="clientData.country">
                                        <vs-select-item :key="index" :value="item.value" :text="item.label"
                                                        v-for="item, index in countries"/>
                                    </vs-select>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-1/2">
                                    <vs-input class="w-full" label="Telefon" data-vv-as="Telefon" name="phone" v-model="clientData.phone" type="text" />
                                </div>

                                <div class="vx-col lg:w-1/2" v-if="!clientData.fast_registration">
                                    <vs-input class="w-full" label="Fax" data-vv-as="Fax" name="fax" v-model="clientData.fax" type="text" />
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="E-Mail-Adresse *" data-vv-as="E-Mail-Adresse" name="email" v-model="clientData.email" type="email" v-validate="'required'"/>
                                    <span class="text-danger text-sm" v-if="errors.has('email')">{{ errors.first('email') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </vx-card>
            </div>
            <div class="vx-col lg:w-1/2 w-full" v-if="!clientData.fast_registration">
                <vx-card title="Weiter Details" class="h-full">
                    <div class="vx-row">
                        <div class="vx-col lg:w-full w-full">
                            <div class="vx-row">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Webseite" data-vv-as="Webseite" name="website" v-model="clientData.websites" type="text" v-validate="'required'"/>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Ust. Id." data-vv-as="Ust. Id." name="ust_id" v-model="clientData.ust_id" type="text" v-validate="'required'"/>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-1/3">
                                    <vs-input class="w-full" label="Payment Deadline" data-vv-as="Payment Deadline" name="payment_deadline" v-model="clientData.payment_deadline" type="text" />
                                </div>
                                <div class="vx-col lg:w-1/3 pt-8">
                                   Tage
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="IBAN" data-vv-as="IBAN" name="bank_iban" v-model="clientData.bank_iban" type="text" />
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="SWIFT/BIC" data-vv-as="SWIFT/BIC" name="bank_bic" v-model="clientData.bank_bic" type="text" />
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Kontoinhaber" data-vv-as="Kontoinhaber" name="bank_account_owner" v-model="clientData.bank_account_owner" type="text" />
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Mindest-Betrag bei automatischer Abrechnung von Accounting-Positionen" data-vv-as="Min. Abrechnung" name="min_cost__every_accounting" v-model="clientData.min_cost__every_accounting" type="text" icon-after="true" icon="euro_symbol" />
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-textarea class="w-full" label="Interner Kommentar" data-vv-as="Interner Kommentar" name="bank_account_owner" v-model="clientData.internal_comment"  />
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Zusätzliche Rechnungsempfänger (Semikolon- oder Kommagetrennt)" data-vv-as="Zusätzliche Rechnungsempfänger" name="additional_invoice_recipient" v-model="clientData.additional_invoice_recipient" type="text" />

                                </div>
                            </div>
                        </div>
                    </div>
                </vx-card>
            </div>
        </div>

        <div class="flex mt-8 justify-end">
            <vs-button color="warning" type="border" class="mb-4" :to="{name :'customers'}">Abbrechen</vs-button>
            <vs-button class="ml-3 mb-4" @click="saveClient">Hinzufügen</vs-button>
        </div>
    </div>
</template>

<script>
    import ApiService from "../../../api";
    import MgSelect from "@/components/mg-select/MgSelect";
    import staticOptions from "@/mixins/static/options";
    import {mapGetters} from 'vuex';

    export default {
        components: {  MgSelect},
        data() {
            return {
                fetched: false,
                countries: [],
                clientData: {
                    workspace_id: null,
                    type:'client',
                    business_client: 1,
                    customer_number: null,
                    company_name: null,
                    address_line_1: null,
                    address_line_2: null,
                    fast_registration: 0,
                    zip_code: null,
                    city: null,
                    country: 'DE',
                    phone: null,
                    fax: null,
                    websites: null,
                    ust_id: null,
                    payment_deadline: 14,
                    internal_comment: null,
                    bank_iban: null,
                    bank_bic: null,
                    bank_account_owner: null,
                    email: null,
                    min_cost__every_accounting: 150,
                    additional_invoice_recipient: null,
                    internal_notice: null,
                },
                selctionOption: {test:"test"},
                nextCustomerNumbers: []
            }
        },
        mounted() {
            this.fetchInitialData();
            this.fetchCountries();
        },
      created() {
        this.clientData.workspace_id = this.workspace.id
      },
      computed: {
        ...mapGetters([
          'workspace'
        ]),
            typeOptions() {
                return staticOptions.clientTypes;
            },
        },
        methods: {
            fetchCountries() {
                this.countries = staticOptions.countries;
            },
            onSelectCustomerType(type){
              this.getNextFreeCustomerNumber(type.value)
            },
            getNextFreeCustomerNumber(type){
              this.clientData.customer_number = this.nextCustomerNumbers[type]
            },
            fetchInitialData() {
                ApiService.get('clients/getNextCustomerNumberByTypes').then((response) => {
                    if(response.data.result) {
                        this.nextCustomerNumbers =  response.data.result;
                        this.clientData.customer_number =  response.data.result[this.clientData.type];
                    }
                    this.fetched = true;
                })
            },
            saveClient() {
                ApiService.post('clients', this.clientData).then((response) => {
                    if(response.data.result) {
                        this.$router.push({ name: 'customers-detail', params: { id: response.data.result.id }})
                    }
                }).catch((error) => {
                });
            },
            selectFastRegistration(){
                this.clientData.websites= null;
                this.clientData.ust_id= null;
                this.clientData.payment_deadline= 14;
                this.clientData.bank_iban= null;
                this.clientData.bank_bic= null;
                this.clientData.bank_account_owner= null;
                this.clientData.min_cost__every_accounting= 150;
                this.clientData.additional_invoice_recipient= null;
                this.clientData.internal_notice= null;
            }

        },
    }
</script>
